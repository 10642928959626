import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from "@angular/core";
import { CartItem } from "../../../Core/models/Cart";
import { CommonModule } from "@angular/common";
import { CartService } from "../../../Core/Services/Cart/cart-service";
import { TranslateModule } from "@ngx-translate/core";
import { CurrencyService } from "../../../Core/Services/Currency/currency.service";

@Component({
  selector: "app-cart-item",
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: "./cart-item.component.html",
  styleUrl: "./cart-item.component.scss",
})
export class CartItemComponent implements OnInit, OnChanges {
  @Input() item?: CartItem;
  @Input() price: any;
  @Input() canDelete: boolean = true;
  @Output() delete = new EventEmitter();
  currency: string = "";
  constructor(
    private cartService: CartService,
    public currencyService: CurrencyService
  ) {}
  ngOnInit(): void {}

  ngOnChanges(): void {
    if (this.item) {
      this.currency = this.price.currencyshortname;
      this.item.brutto_price = this.price.fullpricebrutto;
      this.item.netto_price = this.price.fullpricenetto;
      this.item.afa_price = this.price.fullpriceafa;
      this.price.optionprices.forEach((element: any) => {
        let index = this.item?.extras.findIndex(
          (val) => val.productid == element.productid
        );
        if (index != undefined && this.item) {
          this.item.extras[index].brutto_price = element.fullpricebrutto;
          this.item.extras[index].netto_price = element.fullpricenetto;
          this.item.extras[index].afa_price = element.fullpriceafa;
        }
      });
    }
  }

  setPrices() {
    if (this.item) {
      this.currency = this.price.currencyshortname;
      this.item.brutto_price = this.price.fullpricebrutto;
      this.item.netto_price = this.price.fullpricenetto;
      this.item.afa_price = this.price.fullpriceafa;

      this.price.optionprices.forEach((element: any) => {
        let index = this.item?.extras.findIndex(
          (val) => val.productid == element.productid
        );
        if (index != undefined && this.item) {
          this.item.extras[index].brutto_price = element.fullpricebrutto;
          this.item.extras[index].netto_price = element.fullpricenetto;
          this.item.extras[index].afa_price = element.fullpriceafa;
        }
      });
    }
  }

  deleteItem() {
    if (this.item) {
      this.cartService.removeItem(this.item);
      this.delete.emit();
    }
  }
}
