<footer id="footer">
  <div class="container">
    <div class="copyright">
      © 2022-2025 ·
      <strong
        ><a href="https://clmaster.cloud/" class="text-reset">{{
          "footer.company" | translate
        }}</a></strong
      >
      · {{ "footer.all_rights_reserved" | translate }}

      <br />
      <a [href]="'footer.docs.privacy_policy' | translate" target="_blank">{{
        "footer.privacy_policy" | translate
      }}</a>
      <span class="px-2">·</span>
      <a [href]="'footer.docs.gtc' | translate" target="_blank"
        >{{ "footer.gtc" | translate }}
      </a>
    </div>
    <div class="text-center">
      <a
        href="https://facebook.com/profile.php?id=61558990989694&notif_id=1718375779497496"
        target="_blank"
        class="m-2"
        ><i class="bi bi-facebook fs-2 logo"></i
      ></a>
      <a
        href="https://linkedin.com/company/105097050"
        class="m-2"
        target="_blank"
      >
        <i class="bi bi-linkedin fs-2 logo"></i>
      </a>

      <a
        href="https://instagram.com/cloudmastervps/"
        class="m-2"
        target="_blank"
      >
        <i class="bi bi-instagram fs-2 logo"></i>
      </a>
      <a
        href="https://www.youtube.com/channel/UCfRYjNksu0YHabHfKnWFRiQ"
        class="m-2"
        target="_blank"
      >
        <i class="bi bi-youtube fs-2 logo"></i>
      </a>
    </div>
  </div>
</footer>
