<section *ngIf="vps_category" class="section products-section">
  <h1 class="category-title">{{ vps_category.name }}</h1>
  <p
    class="category-description"
    [innerHtml]="sanitizer.bypassSecurityTrustHtml(vps_category.html)"
  ></p>
  <div class="products-container">
    <app-vps-card
      *ngFor="let vps of vps_category.products"
      [vps]="vps"
      [category]="vps_category.name"
      [currency]="currency"
    ></app-vps-card>
  </div>
</section>
