import {
  Component,
  NgZone,
  TemplateRef,
  ViewChild,
  inject,
} from "@angular/core";
import { CartService } from "../../Core/Services/Cart/cart-service";
import { CommonModule } from "@angular/common";
import { CartItemComponent } from "./cart-item/cart-item.component";
import { ApiService } from "../../Core/Services/Api/api.service";
import { UserService } from "../../Core/Services/User/user.service";
import {
  NgbModalModule,
  NgbOffcanvas,
  NgbTooltipModule,
} from "@ng-bootstrap/ng-bootstrap";
import { UserCanvasComponent } from "../../Core/menu/user-canvas/user-canvas.component";
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from "@angular/forms";
import { Register } from "../../Core/models/Register";
import { CustomerType } from "../../Core/models/Master";
import RevolutCheckout from "@revolut/checkout";

import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { BillingDataComponent } from "../user/register/billing-data/billing-data.component";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastService } from "../../Core/Toast/toast.service";
import { Subscription } from "rxjs";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { Card } from "../../Core/models/Card";
import { environment } from "../../../environments/environment";
import { CurrencyService } from "../../Core/Services/Currency/currency.service";
import { UserDataComponent } from "../user/register/user-data/user-data.component";
import { RegisterComponent } from "../../Core/Components/register/register.component";
import { SuccessModalComponent } from "./success-modal/success-modal.component";
import { UrlCartService } from "../../Core/Services/Cart/url-cart-service";
import { CartItem } from "../../Core/models/Cart";

@Component({
  selector: "app-cart",
  standalone: true,
  host: {
    class: "flex-shrink-0 flex-grow-1",
  },
  imports: [
    CommonModule,
    CartItemComponent,
    RegisterComponent,
    FormsModule,
    BillingDataComponent,
    TranslateModule,
    NgbModalModule,
    RegisterComponent,
    ReactiveFormsModule,
    NgbTooltipModule,
  ],
  templateUrl: "./cart.component.html",
  styleUrl: "./cart.component.scss",
})
export class UrlCartComponent {
  prices: any;
  currency: string = "";
  password2: string = "";
  cupon: string = "";
  cupon_10: boolean = false;
  passwordLengthError: boolean = false;
  passwordMatchError: boolean = false;
  data: Register = { name: "", email: "", password: "", cname: "", typeid: 1 };
  CustomerTypes: CustomerType[] = [];

  BillingAddresses: any[] = [];
  registerNewUser = false;
  activated = false;

  selectedBillingAddressId?: any;
  _cartService: any;
  pricetypes: any[] = [];

  avaibalePeriods: { pricetypeid: number }[] = [];

  selectedPricetype: number = this.cartService.Cart.period
    ? this.cartService.Cart.period
    : 1;
  private modalService = inject(NgbModal);

  check_1: boolean = false;

  check_2: boolean = false;
  check_3: boolean = false;
  coupon_changeable: boolean = false;
  constructor(
    public cartService: CartService,
    private apiService: ApiService,
    public userService: UserService,
    private router: Router,
    private offcanvasService: NgbOffcanvas,
    private zone: NgZone,
    private toastService: ToastService,
    private translateService: TranslateService,
    public currencyService: CurrencyService,
    private route: ActivatedRoute
  ) {
    this._cartService = this.cartService;
  }

  onLangChange?: Subscription;
  onCurrencyChange?: Subscription;
  onUserLoggedIn?: Subscription;
  cards: Card[] = [];
  selectedCard: Card | null = null;
  disableOrderBtn: boolean = false;
  ngOnDestroy(): void {
    this.onLangChange?.unsubscribe();
    this.onUserLoggedIn?.unsubscribe();
  }
  ngOnInit(): void {
    this.onLangChange = this.translateService.onLangChange.subscribe((val) => {
      this.apiCall();
    });
    this.onCurrencyChange = this.currencyService.onChange.subscribe((val) => {
      this.apiCall();
    });
    this.onUserLoggedIn = this.userService.userLoggedIn.subscribe((val) => {
      this.apiCall();
    });
    this.apiCall();
  }

  isAvailable(id: number) {
    return this.avaibalePeriods.findIndex((o) => o.pricetypeid == id) != -1;
  }

  getUrlCart() {
    this.route.params.subscribe((params) => {
      if (params["url"]) {
        this.apiService.getKuponDataByUrl(params["url"]).subscribe({
          next: (response) => {
            if (response.error) {
              this.toastService.showAlert(response.error);
              this.router.navigate(["/"]);
              return;
            } else {
              this.cupon = response.data.code;
              this.cartService = new UrlCartService(
                response.data.productid,
                response.data.systemid,
                this.apiService,
                this.translateService,
                response.data.pricetypeid
              );
              this.cartService.onLoaded.subscribe((success) => {
                this.get_prices();
              });
            }
          },
        });
      }
    });
  }

  apiCall() {
    this.userService.email.subscribe((email) => (this.data.email = email));
    this.apiService.getPriceTypes().subscribe({
      next: (response) => {
        this.pricetypes = response.data;
        this.getUrlCart();
      },
    });

    this.apiService.getUserCards().subscribe({
      next: (response) => {
        this.cards = response.data;
        if (this.cards && this.cards.length > 0) {
          this.selectedCard = this.cards[0];
        }
      },
    });

    this.apiService.getCustomerTypes().subscribe({
      next: (value) => {
        if (value.result && value.data) {
          this.CustomerTypes = value.data;
        }
      },
      error: (error) => {},
      complete: () => {},
    });
    this.loadBillingAddresses();
  }

  get_prices() {
    this.selectedPricetype = this.cartService.Cart.period
      ? this.cartService.Cart.period
      : 1;
    this.avaibalePeriods = [{ pricetypeid: this.selectedPricetype }];
    let cupon = "";
    if (this.cupon.length == 10) {
      cupon = this.cupon;
    }
    this.apiService
      .priceCalc(this.cartService.Cart.items, this.selectedPricetype, cupon)
      .subscribe({
        next: (response) => {
          if (response.error) {
            this.toastService.showAlert(response.error);
          }
          this.prices = response.data;
          if (this.prices.items.length) {
            this.currency = this.prices.items[0].currencyshortname;
          }
        },
        error: (err) => {
          console.log(err);
        },
        complete: () => {},
      });
  }

  clearCart() {
    this.cartService.clearCart();
  }

  loadBillingAddresses() {
    this.apiService.GetCustomerAddress().subscribe({
      next: (response) => {
        if (response.result) {
          this.BillingAddresses = response.data;
          if (response.data.length > 0)
            this.selectedBillingAddressId = response.data[0].id;
        }
      },
    });
  }

  openUserOffCanvas() {
    this.offcanvasService
      .open(UserCanvasComponent, {
        ariaLabelledBy: "offcanvas-basic-title",
        position: "end",
      })
      .dismissed.subscribe({
        next: (res) => {
          if (res == "logged_in") {
            this.loadBillingAddresses();
          }
        },
      });
  }

  open(content: TemplateRef<any>) {
    this.modalService.open(content, {
      ariaLabelledBy: "modal-basic-title",
      size: "xl",
    });
  }

  addBillingAddress(data: FormGroup) {
    this.apiService.GetCustomer().subscribe({
      next: (response) => {
        this.apiService
          .AddCustomerAddress(response.data[0].id, data)
          .subscribe({
            next: (response) => {
              this.modalService.dismissAll();
              this.loadBillingAddresses();
            },
          });
      },
    });
  }

  onPaymentSuccess() {}

  webOrder() {
    this.disableOrderBtn = true;
    this.apiService.GetCustomer().subscribe({
      next: (response) => {
        let cupon = "";
        if (this.cupon.length == 10) {
          cupon = this.cupon;
        }
        let post = {
          customerid: response.data[0].id,
          customeraddressid: this.selectedBillingAddressId,
          currencyid: 1,
          pricetypeid: this.selectedPricetype,
          kupon: cupon,
        };
        this.apiService
          .webOrder(this.cartService.Cart.items, post, this.selectedCard)
          .subscribe({
            next: (response) => {
              if (this.selectedCard) {
                this.cartService.clearCart();
                this.cartService.order_success = true;
                this._cartService.order_success = true;
                this.zone.run(() => {
                  this.router.navigate(["/order/success"]);
                });
              } else {
                RevolutCheckout(
                  response.data.revolut.token,
                  environment.revolut_sabdbox ? "sandbox" : "prod"
                ).then((instance) => {
                  instance.payWithPopup({
                    onSuccess: () => {
                      this.cartService.clearCart();
                      this.cartService.order_success = true;
                      this._cartService.order_success = true;
                      this.zone.run(() => {
                        this.router.navigate(["/order/success"]);
                      });
                    },
                    onCancel: () => {
                      this.cartService.clearCart();
                      this.zone.run(() => {
                        this.router.navigate(["/user/orders"]);
                      });
                    },
                    onError: () => {
                      this.cartService.clearCart();
                      this.zone.run(() => {
                        this.router.navigate(["/user/orders"]);
                      });
                    },
                    email: this.data.email,
                    savePaymentMethodFor: "merchant",
                  });
                });
              }
            },
          });
      },
    });
  }

  cuponChanged() {
    if (this.cupon.length != 10) {
      this.cupon_10 = false;
      this.get_prices();
    }
    if (this.cupon.length == 10 && !this.cupon_10) {
      this.cupon_10 = true;
      this.get_prices();
    }
  }

  onUserRegister() {
    this.modalService.dismissAll();
    this.registerNewUser = true;
  }

  public code: FormGroup = new FormGroup({
    c_1: new FormControl(""),
    c_2: new FormControl(""),
    c_3: new FormControl(""),
    c_4: new FormControl(""),
    c_5: new FormControl(""),
    c_6: new FormControl(""),
  });

  next_input(current: string, next: string) {
    if (this.allCodeDone()) {
      this.onCodeSend();
      return;
    }
    if (current == "") {
      return;
    }
    if (this.code.controls[current].value.length >= 1) {
      document.getElementById(next)?.focus();
    }
  }

  onPaste(event: ClipboardEvent) {
    event.preventDefault();
    let c = event.clipboardData?.getData("text");
    if (c?.length == 6) {
      this.code.controls["c_1"].setValue(c[0]);
      this.code.controls["c_2"].setValue(c[1]);
      this.code.controls["c_3"].setValue(c[2]);
      this.code.controls["c_4"].setValue(c[3]);
      this.code.controls["c_5"].setValue(c[4]);
      this.code.controls["c_6"].setValue(c[5]);
      this.onCodeSend();
    }
  }

  allCodeDone(): boolean {
    return (
      this.code.controls["c_1"].value &&
      this.code.controls["c_2"].value &&
      this.code.controls["c_3"].value &&
      this.code.controls["c_4"].value &&
      this.code.controls["c_5"].value &&
      this.code.controls["c_6"].value
    );
  }

  clearCode(): void {
    this.code.controls["c_1"].setValue("");
    this.code.controls["c_2"].setValue("");
    this.code.controls["c_3"].setValue("");
    this.code.controls["c_4"].setValue("");
    this.code.controls["c_5"].setValue("");
    this.code.controls["c_6"].setValue("");
  }

  onCodeSend() {
    var code =
      this.code.controls["c_1"].value +
      this.code.controls["c_2"].value +
      this.code.controls["c_3"].value +
      this.code.controls["c_4"].value +
      this.code.controls["c_5"].value +
      this.code.controls["c_6"].value;
    this.apiService.UserActivate(code).subscribe({
      next: (response) => {
        if (!response.result) {
          this.clearCode();
          return;
        }
        this.userService.refreshData().subscribe({
          next: (response) => {
            this.activated = response.data.activated;
          },
        });
      },
      error: (err) => {
        console.log(err);
      },
    });
  }
}
