{
  "name": "clm-portal",
  "version": "0.9",
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "build": "ng build",
    "watch": "ng build --watch --configuration development",
    "test": "ng test"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^17.3.0",
    "@angular/cdk": "^17.3.0",
    "@angular/common": "^17.3.0",
    "@angular/compiler": "^17.3.0",
    "@angular/core": "^17.3.0",
    "@angular/forms": "^17.3.0",
    "@angular/material": "^17.3.0",
    "@angular/platform-browser": "^17.3.0",
    "@angular/platform-browser-dynamic": "^17.3.0",
    "@angular/router": "^17.3.0",
    "@auth0/angular-jwt": "^5.2.0",
    "@fortawesome/angular-fontawesome": "^0.14.0",
    "@fortawesome/fontawesome-svg-core": "^6.4.2",
    "@fortawesome/free-solid-svg-icons": "^6.4.2",
    "@ng-bootstrap/ng-bootstrap": "^16.0.0",
    "@ngx-translate/core": "^15.0.0",
    "@ngx-translate/http-loader": "^8.0.0",
    "@novnc/novnc": "^1.1.0",
    "@popperjs/core": "^2.11.8",
    "@revolut/checkout": "^1.1.19",
    "bootstrap": "^5.3.2",
    "bootstrap-icons": "^1.11.3",
    "cookieconsent": "^3.1.1",
    "flag-icons": "^7.1.0",
    "inherits": "^2.0.4",
    "jsonrpc-websocket-client": "^0.7.2",
    "jws": "^4.0.0",
    "lodash": "^4.17.2",
    "make-error": "^1.0.4",
    "ngx-cookie-service": "^17.0.1",
    "ngx-cookieconsent": "^6.0.0",
    "ngx-scrollreveal": "^3.0.0",
    "rxjs": "~7.8.0",
    "scrollreveal": "^4.0.9",
    "tslib": "^2.3.0",
    "websocket": "^1.0.35",
    "zone.js": "~0.14.2"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^17.3.0",
    "@angular/cli": "^17.3.0",
    "@angular/compiler-cli": "^17.3.0",
    "@angular/localize": "^17.3.0",
    "@types/gtag.js": "^0.0.20",
    "@types/jasmine": "~5.1.0",
    "@types/scrollreveal": "^0.0.11",
    "jasmine-core": "~5.1.0",
    "karma": "~6.4.0",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.0",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "typescript": "~5.2.2"
  }
}
