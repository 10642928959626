import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { CookieService } from "ngx-cookie-service";
import { NgcCookieConsentService } from "ngx-cookieconsent";
import { Subscription } from "rxjs";
import { ClmCookieService } from "../Cookie/clm-cookie.service";
import { environment } from "../../../../environments/environment";

declare let gtag: Function;

@Injectable({
  providedIn: "root",
})
export class CookieConsetService {
  private popupOpenSubscription!: Subscription;
  private popupCloseSubscription!: Subscription;
  private initializingSubscription!: Subscription;
  private initializedSubscription!: Subscription;
  private initializationErrorSubscription!: Subscription;
  private statusChangeSubscription!: Subscription;
  private revokeChoiceSubscription!: Subscription;
  private noCookieLawSubscription!: Subscription;
  private onLangChange!: Subscription;
  constructor(
    private translateService: TranslateService,
    private cookieService: ClmCookieService,
    private cookieConsentService: NgcCookieConsentService
  ) {}

  initialize() {
    if (environment.production && !this.cookieConsentService.hasConsented()) {
      gtag("consent", "default", {
        ad_storage: "denied",
        ad_user_data: "denied",
        ad_personalization: "denied",
        analytics_storage: "denied",
      });
    }
    this.statusChangeSubscription =
      this.cookieConsentService.statusChange$.subscribe({
        next: (value) => {
          if (value && value.status === "allow") {
            if (environment.production) {
              gtag("consent", "update", {
                ad_user_data: "granted",
                ad_personalization: "granted",
                ad_storage: "granted",
                analytics_storage: "granted",
              });
            }

            this.cookieService.set(
              "language",
              this.translateService.currentLang
            );
          } else {
            if (environment.production) {
              gtag("consent", "default", {
                ad_storage: "denied",
                ad_user_data: "denied",
                ad_personalization: "denied",
                analytics_storage: "denied",
              });
            }
          }
        },
      });

    this.onLangChange = this.translateService.onLangChange.subscribe((data) => {
      this.setLang();
    });
  }

  setLang() {
    this.translateService
      .get([
        "cookie.header",
        "cookie.message",
        "cookie.dismiss",
        "cookie.allow",
        "cookie.deny",
        "cookie.link",
        "cookie.policy",
      ])
      .subscribe((data) => {
        this.cookieConsentService.getConfig().content =
          this.cookieConsentService.getConfig().content || {};
        let content = this.cookieConsentService.getConfig().content || {};
        // Override default messages with the translated ones
        content.header = data["cookie.header"];
        content.message = data["cookie.message"];
        content.dismiss = data["cookie.dismiss"];
        content.allow = data["cookie.allow"];
        content.deny = data["cookie.deny"];
        content.link = data["cookie.link"];
        content.policy = data["cookie.policy"];
        this.cookieConsentService.destroy(); // remove previous cookie bar (with default messages)
        this.cookieConsentService.init(this.cookieConsentService.getConfig()); // update config with translated messages
      });
  }

  cleanUp() {
    this.popupOpenSubscription.unsubscribe();
    this.popupCloseSubscription.unsubscribe();
    this.initializingSubscription.unsubscribe();
    this.initializedSubscription.unsubscribe();
    this.initializationErrorSubscription.unsubscribe();
    this.statusChangeSubscription.unsubscribe();
    this.revokeChoiceSubscription.unsubscribe();
    this.noCookieLawSubscription.unsubscribe();
    this.onLangChange.unsubscribe();
    // Cleanup logic here
  }
}
