import { Routes } from "@angular/router";
import { MainComponent } from "./Pages/main/main.component";
import { RegisterComponent } from "./Pages/user/register/register.component";
import { ProfileComponent } from "./Pages/user/profile/profile.component";
import { CartComponent } from "./Pages/cart/cart.component";
import { LoggedIn, NotLoggedIn } from "./Core/Guards/auth.guard";
import { ActivationComponent } from "./Pages/user/register/activation/activation.component";
import { Activated, NotActivated } from "./Core/Guards/activated.guard";
import { ForgotPasswordComponent } from "./Pages/user/forgot-password/forgot-password.component";
import { ResetPasswordComponent } from "./Pages/user/reset-password/reset-password.component";
import { ServicesComponent } from "./Pages/user/services/services.component";
import { OrdersComponent } from "./Pages/user/orders/orders.component";
import { NotFoundComponent } from "./Pages/errors/not-found/not-found.component";
import { SupportComponent } from "./Pages/user/support/support.component";
import { FAQComponent } from "./Pages/faq/faq.component";
import { SubscriptionsComponent } from "./Pages/user/subscriptions/subscriptions.component";
import { InvoicesComponent } from "./Pages/user/invoices/invoices.component";
import { VpsCategoriesPageComponent } from "./Pages/vps/vps-categories-page/vps-categories-page.component";
import { VpsConfigPageComponent } from "./Pages/vps/vps-config-page/vps-config-page.component";
import { ContactSectionComponent } from "./Pages/contact-section/contact-section.component";
import { TestComponent } from "./Pages/test/test.component";
import { ConfirmComponent } from "./Pages/user/register/confirm/confirm.component";
import { DedicatedCategoriesPageComponent } from "./Pages/dedicated/dedicated-categories-page/dedicated-categories-page.component";
import { DedicatedConfigPageComponent } from "./Pages/dedicated/dedicated-config-page/dedicated-config-page.component";
import { ServerColocationComponent } from "./Pages/server-colocation/server-colocation.component";
import { SuccessDedicatedModalComponent } from "./Pages/cart/success-dedicated-modal/success-dedicated-modal.component";
import { SuccessModalComponent } from "./Pages/cart/success-modal/success-modal.component";
import { EduServiceComponent } from "./Pages/edu-service/edu-service.component";
import { PostOrderComponent } from "./Pages/post-order/post-order.component";
import { BlogsComponent } from "./Pages/blogs/blogs.component";
import { BlogComponent } from "./Pages/blogs/blog/blog.component";
import { SystemIntegrationComponent } from "./Pages/system-integration/system-integration.component";
import { ClassicHostingServicesComponent } from "./Pages/classic-hosting-services/classic-hosting-services.component";
import { AutomatizationComponent } from "./Pages/automatization/automatization.component";
import { AboutComponent } from "./Pages/about/about.component";
import { UrlCartComponent } from "./Pages/cart/url_cart.component";

export const routes: Routes = [
  {
    path: "",
    component: MainComponent,
    canActivate: [Activated],
    data: { animation: "Home" },
  },
  {
    path: "vps",
    component: VpsCategoriesPageComponent,
    data: { animation: "VpsCategory" },
  },
  {
    path: "vps/:category/:product",
    component: VpsConfigPageComponent,
    data: { animation: "Vps" },
  },
  {
    path: "dedicated",
    component: DedicatedCategoriesPageComponent,
    data: { animation: "DedicatedCategory" },
  },
  {
    path: "dedicated/:category/:product",
    component: DedicatedConfigPageComponent,
    data: { animation: "Dedicated" },
  },
  {
    path: "servercolocation",
    component: ServerColocationComponent,
    data: { animation: "ServerColocation" },
  },
  {
    path: "eduservice",
    component: EduServiceComponent,
    data: { animation: "EduService" },
  },
  {
    path: "system-integration",
    component: SystemIntegrationComponent,
    data: { animation: "SystemIntegration" },
  },
  {
    path: "automatization",
    component: AutomatizationComponent,
    data: { animation: "Automatization" },
  },
  {
    path: "classic-hosting-services",
    component: ClassicHostingServicesComponent,
    data: { animation: "ClassicHostingService" },
  },
  {
    path: "register",
    component: RegisterComponent,
    canActivate: [NotLoggedIn, Activated],
    data: { animation: "Register" },
  },
  {
    path: "user/profile",
    component: ProfileComponent,
    canActivate: [LoggedIn, Activated],
    data: { animation: "Profile" },
  },
  {
    path: "user/orders",
    component: OrdersComponent,
    canActivate: [LoggedIn, Activated],
    data: { animation: "Orders" },
  },
  {
    path: "user/services",
    component: ServicesComponent,
    canActivate: [LoggedIn, Activated],
    data: { animation: "Services" },
  },
  {
    path: "user/support",
    component: SupportComponent,
    canActivate: [LoggedIn, Activated],
    data: { animation: "Support" },
  },
  {
    path: "user/subscriptions",
    component: SubscriptionsComponent,
    canActivate: [LoggedIn, Activated],
    data: { animation: "Subscriptions" },
  },
  {
    path: "user/invoices",
    component: InvoicesComponent,
    canActivate: [LoggedIn, Activated],
    data: { animation: "Invoices" },
  },
  {
    path: "about",
    component: AboutComponent,
    data: { animation: "About" },
  },
  {
    path: "cart",
    component: CartComponent,
    canActivate: [Activated],
    data: { animation: "Cart" },
  },
  {
    path: "cart/:url",
    component: UrlCartComponent,
    canActivate: [Activated],
    data: { animation: "Cart" },
  },
  {
    path: "order/success",
    component: PostOrderComponent,
    canActivate: [Activated, LoggedIn],
  },
  {
    path: "register/activation",
    component: ActivationComponent,
    canActivate: [NotActivated],
  },
  {
    path: "confirm/:secret",
    component: ConfirmComponent,
  },
  {
    path: "forgotPassword",
    component: ForgotPasswordComponent,
    canActivate: [NotLoggedIn],
  },
  {
    path: "resetpassword/:secret",
    component: ResetPasswordComponent,
    canActivate: [NotLoggedIn],
  },
  {
    path: "faq",
    component: FAQComponent,
    data: { animation: "Faq" },
  },
  {
    path: "blogs",
    component: BlogsComponent,
    data: { animation: "Blogs" },
  },
  {
    path: "blogs/:url",
    component: BlogComponent,
    data: { animation: "Blog" },
  },
  {
    path: "test",
    component: TestComponent,
  },
  {
    path: "**",
    component: NotFoundComponent,
  },
];
