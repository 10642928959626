import { EventEmitter, Inject, Injectable } from "@angular/core";
import { Cart, CartExtra, CartItem } from "../../models/Cart";
import { ApiService } from "../Api/api.service";
import { CartService } from "./cart-service";
import { TranslateService } from "@ngx-translate/core";

@Injectable({
  providedIn: "root",
})
export class UrlCartService {
  Cart: Cart = { items: [] };
  cart_str: string = "cart";

  order_success: boolean = false;

  public onLoaded: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    @Inject(Number) private _productid: number,
    @Inject(Number) private _osid: number,
    private apiService: ApiService,
    private translateService: TranslateService,
    @Inject(Number) private period: number
  ) {
    this.Cart.period = this.period;
    this.loadCart();
  }

  addToCart(addedItem: CartItem, period: number | undefined = undefined) {
    this.Cart.period = period;
    this.Cart.items.push(addedItem);
    this.saveCart();
  }

  getItems() {
    return this.Cart.items;
  }

  getIds() {
    return this.Cart.items.map((o) => o.productid);
  }

  loadCart(): void {
    this.apiService.getProductData(this._productid).subscribe((response) => {
      this.apiService.getProductData(this._osid).subscribe((os_response) => {
        let extra: CartExtra = {
          name: this.translateService.instant("basic.os"),
          item: os_response[0].name,
          productid: os_response[0].id,
          quantity: 1,
          type: "select",
        };
        this.Cart = { items: [] };
        let Item: CartItem = {
          productid: response[0].id,
          quantity: 1,
          product: response[0],
          extras: [extra],
          name: response[0].name,
          params: response[0].params,
        };
        this.addToCart(Item, this.period);
        this.onLoaded.emit(true);
      });
    });
  }

  saveCart(): void {
    //localStorage.setItem(this.cart_str, JSON.stringify(this.Cart));
  }

  clearCart(): void {
    //this.Cart = { items: [] };
    //localStorage.removeItem(this.cart_str);
  }

  removeItem(item: CartItem) {
    const index = this.Cart.items.findIndex(
      (o) => o.productid == item.productid
    );
    if (index > -1) {
      this.Cart.items.splice(index, 1);
      if (this.Cart.items.length == 0) {
        this.Cart.period = undefined;
      }
      this.saveCart();
    }
  }

  itemInCart(item: CartItem): boolean {
    return (
      this.Cart.items.findIndex((o) => o.productid === item.productid) > -1
    );
  }

  prodInCartCount(id: number): number {
    return this.Cart.items.filter((o) => o.productid === id).length;
  }
}
