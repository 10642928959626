<div class="card m-2 p-2">
  <h2 class="p-2">
    {{ item?.name }}
    <div class="float-end">
      <button
        class="btn btn-outline-danger"
        (click)="deleteItem()"
        *ngIf="canDelete"
      >
        {{ "cart.item.delete" | translate }}
      </button>
    </div>
    <br />
  </h2>
  <div class="row" style="overflow-x: auto">
    <div class="col-12">
      <table class="table table-striped">
        <thead>
          <th>{{ "cart.item.service" | translate }}</th>
          <th></th>
          <th>{{ "cart.item.netto" | translate }}</th>
          <th>{{ "cart.item.afa" | translate }}</th>
          <th>{{ "cart.item.brutto" | translate }}</th>
        </thead>
        <tbody>
          <tr>
            <td>{{ item?.name }}</td>
            <td></td>
            <td>
              {{ currencyService.printCurrency(item?.netto_price) }}
            </td>

            <td>{{ currencyService.printCurrency(item?.afa_price) }}</td>
            <td>{{ currencyService.printCurrency(item?.brutto_price) }}</td>
          </tr>
          <ng-container *ngFor="let param of item?.params">
            <tr>
              <td class="ps-5">{{ param.name }}</td>
              <td>{{ param.value }}</td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          </ng-container>
          <ng-container *ngFor="let extra of item?.extras">
            <tr *ngIf="extra.type == 'chek'">
              <td>
                {{ extra.name }}
              </td>
              <td></td>
              <td>{{ currencyService.printCurrency(extra?.netto_price) }}</td>
              <td>{{ currencyService.printCurrency(extra?.afa_price) }}</td>
              <td>
                {{ currencyService.printCurrency(extra?.brutto_price) }}
              </td>
            </tr>
            <tr *ngIf="extra.type == 'select'">
              <td>{{ extra.name }}</td>
              <td>{{ extra.item }}</td>
              <td>{{ currencyService.printCurrency(extra?.netto_price) }}</td>
              <td>{{ currencyService.printCurrency(extra?.afa_price) }}</td>
              <td>
                {{ currencyService.printCurrency(extra?.brutto_price) }}
              </td>
            </tr>
            <tr *ngIf="extra.type == 'csuszka'">
              <td>{{ extra.name }}</td>
              <td>{{ extra.quantity }} {{ extra.item }}</td>
              <td>{{ currencyService.printCurrency(extra?.netto_price) }}</td>
              <td>{{ currencyService.printCurrency(extra?.afa_price) }}</td>
              <td>
                {{ currencyService.printCurrency(extra?.brutto_price) }}
              </td>
            </tr>
          </ng-container>
          <tr>
            <td></td>
            <td>{{ "cart.item.summary" | translate }}</td>
            <td>{{ currencyService.printCurrency(price.sumpricenetto) }}</td>
            <td>{{ currencyService.printCurrency(price.sumpriceafa) }}</td>
            <td>
              {{ currencyService.printCurrency(price.sumpricebrutto) }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
